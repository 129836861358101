section#download {
  background: linear-gradient(
    90deg,
    rgb(1, 78, 222) 45%,
    rgb(42, 147, 232) 100%
  );
  text-align: center;
  color: #fff;
}

.download h2 {
  font-size: 3rem;
  font-weight: 200;
  line-height: 1.4;
  text-transform: uppercase;
  margin: 1.5rem 0;
  position: relative;
  padding-bottom: 1rem;
}

.download h2::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 10%;
  height: 1px;
  background: var(--color-white);
  transform: translateX(-50%);
}
.download-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem;
}

.download-icon {
  margin: 1rem 2rem;
  border: 2px solid #fff;
  border-radius: 5px;
  width: 12rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  font-size: 1.5rem;
}
.sponsor-image{
  margin: 2rem;
  padding: 1rem;
  width: 15rem;
  height: 15rem;
  border: 2px solid var(--light-blue);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  cursor: pointer;
  transition: all 0.3s;
}
.sponsor-image:hover{
  background: var(--light-blue);
}
.sponsor-image img{
  width: 90%;
  height: 90%;
}
.video-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (max-width: 768px) {
  .social-icons {
    display: flex;
    flex-wrap: wrap; /* Wrap items into multiple lines */
    justify-content: center; /* Center items horizontally */
  }

  .sponsor-image {
    margin: 1rem; /* Adjust margin to create space between images */

  }
}