.feature {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}

.feature-icon {
 color: orange;
}

.inner-icon {
  /* position: relative; */
  margin: 2rem;
  color: var(--light-blue);
  size: 30;

}

.feature-text h3 {
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.2;
  text-transform: uppercase;
  color: rgb(48, 47, 47);
}
.feature-text p{
  font-size: 1.5rem;
  
}
.result{
  position: relative;
 margin-left: 2rem;
 font-size: 1rem;
}
