#players{
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background: url('https://images.unsplash.com/photo-1653649009593-5614b25b8428?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NDN8fGtuaWdodCUyMGNoZXNzfGVufDB8fDB8fHww');
    background-size: 100%; 
    background-repeat: no-repeat;
}
.container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 0 20px;
  }
  .table {
    padding: 5px;
    width: 100%;
    overflow-x: auto;
}
table {
    border-collapse: collapse;
    width: 100%;
    font-size: 1.4rem;
}
    thead {
      border-top: 2px solid var(--light-blue);
      border-bottom: 2px solid var(--light-blue);
    }

    th {
      border: 1px solid #eee;
    
    }

    th,
    td {
      vertical-align: top;
      text-align: left;
      padding: 8px;
    }
    tr:first-child(){
        border-radius: 2rem; 
    }
    .tablerow:nth-child(2n + 1){
background: rgb(217, 230, 243);

    }