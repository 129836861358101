
.video-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh; 
    background-color: #f0f0f0;
    margin: 3rem;
    padding: 1rem;
  }
  
  .react-player {
    width: 200px;
    height: 200px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }