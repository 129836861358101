.Tournament{
    display:flex;
    /* justify-content: center;
    align-items: center; */
  text-align: center;
  color: #1f93ff;
 height: 100vh;
    width: 100vw;

}
.Tournament-video{
    height: 90vh;
    width: 100vw;
    /* background-color: red; */
}
.Tournament-video video{
    height:60%;
    width: 100%;
    outline: none;
}