.slider {
    width: 80%;
    height: 80vh;
    position: relative;
    overflow: hidden;
    background-color: var(--color-dark);
 border-radius: 2.5rem ;
 }
  
  .slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transform: translateX(-50%);
    transition: all 0.5s ease;
 
  }
  

  .slide img {
    width: 100%;
    height: 100%;
  }
  
  .content {
    position: absolute;
    text-align: center;
    top: 60%;
    left: 50%;
    opacity: 0;
    /* width: 50%; */
     width: 500px;
     height: 500px;
    padding: 3rem;
    display: flex;
    justify-self: center;
    align-items: center;
    flex-direction: column;
    transform: translateX(-50%);
    background: rgba(0, 0, 0, 0.227);
 
   animation: slide-up 1s ease 0.5s;
   animation-fill-mode: forwards; 
    box-sizing: border-box;
    overflow: hidden;
    box-shadow: 0 20px 50px rgb(0, 0, 0);
    h2 {
      color:#fffffffd;
      font-size: 2rem;
    }
    button{
      cursor: pointer;
      z-index: 200;
    }
  }

  
  .content span,
  .content .span1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    box-sizing: border-box;
  }
  
 /* .content .span1:before {
  content: "";  
   position: absolute;
  top: 0px;
  left: 0%;
  width: 30%;
  height: 2px;
   background: #50dfdb;
  
  animation: span1 3s linear infinite;
  }  */
  /* .content .span2:before {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0%;
    width: 30%;
    height: 1px;
    background: orangered;
    animation: span2 3s linear infinite;
  } */
   /* .content .span3:before {
     content: "";
     position: absolute;
     top: 0%;
     left: 0;
     width: 2px;
     height: 30%;
     background: #50dfdb;
     animation: span3 3s linear infinite;
   } */
   /* .content .span4:before {
     content: "";
     position: absolute;
     bottom: 0%;
     right: 0;
     width: 2px;
     height: 30%;
     background: #50dfdb;
     animation: span4 3s linear infinite;
   } */
  
  @keyframes span1 {
    0% {
      left: 0%;
    }
    100% {
      left: 100%;
    }
  }
  @keyframes span2 {
    0% {
      right: 0%;
    }
    100% {
      right: 100%;
    }
  }
  @keyframes span3 {
    0% {
      top: 0%;
    }
    100% {
      top: 100%;
    }
  }
  @keyframes span4 {
    0% {
      bottom: 0%;
    }
    100% {
      bottom: 100%;
    }
  }
  
  @keyframes slide-up {
    0% {
      visibility: visible;
      top: 0;
    }
    100% {
      visibility: visible;
      top: 20rem;
    }
  }
  


  
  .content > * {
    color: #fff;
    margin-bottom: 1rem;
  }
  
  .current {
    opacity: 1;
    transform: translateX(0);
  }
  
  .current .content {
    opacity: 1;
  }
  .arrow {
    border: 2px solid rgb(235, 233, 233);
    border-radius: 50%;
    background: transparent;
    color: #fff;
    width: 2.5rem;
    height: 2.5rem;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
  }
  
  .arrow:hover {
    background: #fff;
  }
  
  .next {
    right: 1.5rem;
    color: var(--light-blue);
  }
  .prev {
    left: 1.5rem;
    color: var(--light-blue);
  }
  
  hr {
    height: 2px;
    background: #fff;
    width: 50%;
  }
  
  @keyframes steam {
    0% {
      background-position: 0 0;
    }
    50% {
      background-position: 400% 0;
    }
    100% {
      background-position: 0 0;
    }
  }

  @media screen and (max-width: 600px) {
    .content {
      width: 70%;
      top: 30%;
    }
    .slider {
      width: 100%;
      height: 55vh;
      position: relative;
      overflow: hidden;
      background-color: var(--color-dark);
    }
    .slide img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
 