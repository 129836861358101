/* Default styles for desktop */
.gallery {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Four columns */
    grid-template-rows: repeat(4, 20rem); /* Four rows with a height of 20rem each */
    grid-gap: 10px;
    /* height: 100vh; */
    margin:2rem ;
}

.video-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh; 
    background-color: #f0f0f0;
    margin: 3rem;
    padding: 1rem;
  }
  
  .react-player {
    width: 200px;
    height: 200px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
.main{
    text-align: center;
    margin-top: 2rem;
    /* display: flex;
    justify-content: center; */
}
/* Tablet styles - three columns per page */
@media screen and (max-width: 1024px) {
    .gallery {
        grid-template-columns: repeat(3, 1fr); /* Three columns */
        grid-template-rows: repeat(6, 20rem); /* Six rows with a height of 20rem each */
    }
}

/* Mobile styles - one column per page */
@media screen and (max-width: 600px) {
    .gallery {
        grid-template-columns: repeat(1, 1fr); /* One column */
        grid-template-rows: repeat(12, 25rem);
        margin: 1rem; /* Twelve rows with a height of 20rem each */
    }
}
  
.gallery-item {
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}

.gallery-item::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%);
    pointer-events: none; /* Ensures clicks pass through */
}

.gallery-item img {
    width: 100%;
    height: 100%;
    display: block;
    border-radius: 8px;
    transition: opacity 0.3s ease; /* Add transition for smoother effect */
    object-fit: cover;
}

.gallery-item:hover img {
    opacity: 0.7; /* Reduce opacity on hover */
}
