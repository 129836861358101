.add-student {
    margin-bottom: 5rem;
    margin-left: 10rem;
    width: 500px;
  
    .card {
      width: 100%;
      padding: 1rem;
    }
    form {
      width: 100%;
      margin: auto;
      padding: 0.7rem;
      .error {
        color: red;
        font-size: 14px;
        margin-top: 5px;
      }
    .age{
      display: flex;
    
      width: 100%;
      p{
        width: 70%;
      }
      label {
        display: block;
        font-size: 1.2rem;
        font-weight: 500;
        color: #3c3c3c;
      }
      input[type="radio"]{
        margin: 0.5rem ;
        width: 20%;
      }
    }
      fieldset{
        margin: 1rem;
        border-radius: 1rem;
        border: 1px solid rgb(68, 68, 255);
        padding: 1rem;
      }
      label {
        display: block;
        font-size: 1.2rem;
        font-weight: 500;
        color: #3c3c3c;
      }
      legend{
        color: #00589b;
        font-size: 1.2rem;
        padding: 1rem;
      }
      input[type="text"],
      input[type="number"],
      input[type="date"],
      input[type="file"],
      input[type="email"],
      select,
      input[type="password"] {
        display: block;
        font-size: 1.2rem;
        font-weight: 300;
        padding: 0.3rem;
        margin: 0.5rem auto;
        width: 100%;
        color: #2c2c2c;
        border: 1px solid #777;
        border-radius: 3px;
        outline: none;
      }
    }
  }
  
  .group {
    border: 1px solid var(--dark-blue);
    padding: 5px;
  }
  
  .image-preview {
    width: 100%;
    height: 100%;
    max-height: 280px;
    background: var(--dark-blue);
    padding: 1rem;
    border-radius: 3px;
    overflow: hidden;
    // display: none;
    transition: all 0.3s;
  }
  
  .image-preview img {
    width: 100%;
  }
  
  .image-show {
    display: block;
  }

  @media screen and (max-width: 800px) {
    .add-student {
      margin-bottom: 5rem;
      margin-left: 1rem;
      max-width: 450px;}
    form {
      background: #ffffffdb;
      width: 80%;
      label {
        display: block;
        font-size: 1.2rem;
        font-weight: 300;
       
        
      }
      .card {
        width: 100%;
        padding: 0.2rem;
      }
    }
    }