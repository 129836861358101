@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

:root {
  --font-family: "Poppins", sans-serif;
  --dark-blue: #0a1930;
  --light-blue: #1f93ff;
  --color-dark:#000000;
  --color-white: #fff;
  --dark-text: #333;
  --form-button: #2da5ff;
  --color-grey: #eae6eb;
  --box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

html {
  font-size: 10px;
}

body {
  font-family: "Poppins", sans-serif;
}

section {
  width: 100%;
  padding: 8rem 0;
}

.container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 20px;
}

/* Text styles */
.u-text-small {
  font-size: 1.2rem;
  line-height: 1.4;
  font-weight: 300;
}

.u-text-dark {
  color: var(--dark-text);
}
.u-text-light {
  color: var(--dark-text);
}

.u-text-center {
  text-align: center;
}

/* Title */
.u-title {
  text-align: center;
  margin-bottom: 4rem;
}

.u-title h2 {
  font-size: 2.5rem;
  font-weight: 300;
  line-height: 1.4;
  text-transform: uppercase;
  color: var(--dark-text);
  margin: 1.5rem 0;
}

.u-title p {
  width: 50%;
  min-width: 40rem;
  margin: 0 auto;
  color: var(--dark-text);
}

.--btn {
  font-size: 1.6rem;
  font-weight: 400;
  padding: 4px 6px;
  margin: 0 5px 0 0;
  border: 1px solid transparent;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
}

.--btn:hover {
  transform: translateY(-2px);
}

.--btn-lg {
  padding: 8px 10px;
}

.--btn-block {
  width: 100%;
}

.--btn-primary {
  color: #fff;
  background: #007bff;
}
.--btn-secondary {
  color: #fff;
  border: 1px solid #fff;
  background: transparent;
}
.--btn-danger {
  color: #fff;
  background: orangered;
}

.--btn-success {
  color: #fff;
  background: #28a745;
}
.--btn-black {
  color: #fff;
  background: #000000;
}
