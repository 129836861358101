section#subscribe {
  background: url(../../assets/IMG-20240402-WA0055.jpg);
  text-align: center;
  color: #fff;
  background-size: cover;
  background-attachment: fixed;
}

.subscribe h2 {
  font-size: 3rem;
  font-weight: 200;
  line-height: 1.4;
  text-transform: uppercase;
  margin: 1.5rem 0;
  padding-bottom: 1rem;
}
.form{
 
  display: flex;
  justify-content: center;
}
form  {
  width: 100%;
  max-width: 50rem;
  margin: 3rem ;
 
}

form input {
  padding: 1rem;
  border: 1px solid #777;
  border-radius: 5px 0 0 5px;
  color: #080808;
  outline: none;
  width: 80%;
  font-size: 1.6rem;
}

form button {
  padding: 0;
  background: rgb(2, 2, 2);
  border: 1px solid rgb(191, 176, 167);
  border-radius: 0 5px 5px 0;
  color: #fff;
  font-size: 1.6rem;
  font-weight: 500;
  cursor: pointer;
  height: 4rem;

  
}
form button:hover{
  background: rgb(255, 255, 255) ;
  
}



.social-icons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-icon {
  margin: 2rem;
  padding: 1rem;
  width: 4rem; /* Initial width */
  min-width: 4rem; /* Minimum width */
  height: 4rem;
  border: 1px solid var(--light-blue);
  border-radius: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  cursor: pointer;
  transition: all 0.3s;
}

.social-icon img{
  width: 100%;
  height: 100%;
}
.social-icon:hover {
  background: var(--light-blue);
}
.form-control{
  background: transparent
}

@media screen and (max-width: 600px) {
  form input {
    width: 69%;
  }
  .social-icon {
    min-width: 3rem; /* Adjust the minimum width for smaller screens */
    font-size: 2.5rem; /* Adjust the font size for smaller screens */
    margin: 1rem;
  }
  
}
