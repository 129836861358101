.title {
  text-align: center;
  margin-bottom: 4rem;
}


.title h2 {
  font-size: 2.5rem;
  font-weight: 300;
  line-height: 1.4;
  text-transform: uppercase;
  color: var(--dark-text);
  margin: 1.5rem 0;
}

.title p {
  width: 50%;
  /* min-width: 40rem; */
  margin: 0 auto;
  color: var(--dark-text);
  font-weight: 500;
}

.features-content {
  display: flex;
  justify-content: center;
  /* padding: 3rem 0; */
}

.features-left {
  flex: 1;
  text-align: center;
  margin: 1rem;

}
.features-left img {
  width: 100%;
  height: 100%;
  border-radius: 1.5rem;
  
}
.features-right {
  flex: 2;
  margin: 1rem;
}
#features a{
  font-size: 16px;
  font-weight: 300;
  color: #fff;
  text-decoration: none;
  margin-right: 1rem;
  transition: all 0.3s;
  border-radius: 1rem;
  height: 5rem;
  padding:1rem;
}
#features a:hover,
#nav-links-mobile a:hover {
  color: var(--light-blue);
}
#features a.btn {
  border-radius: 5px;
  padding: 5px 8px;
  font-weight: 500;
  cursor: pointer;
}

#features a.btn-dark {
  background: transparent;
  border: 2px solid #fff;
}
#features a.btn-light {
  background: var(--light-blue);
  border: 2px solid transparent;
  color: #fff;
}
#features p{
  width: 100%;
}


@media screen and (max-width: 600px) {
  .features-content {
    flex-direction: column;
  }
  a.btn {
    display: inline;
  }
  #features a{
    font-size: 10px;
    text-decoration: none;
    margin-right: 0;
    height: 3rem;
    padding:1rem;
  }
}
