.header-bg {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: url('https://images.unsplash.com/photo-1676479599452-3789bf9eaf0a?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTEyfHxjaGVzcyUyMGJsYWNrfGVufDB8fDB8fHww');
  background-size: 100%; 
  background-color: #2c2b2bc2;
  filter: blur(0.1px); 
}
@media screen and (max-width: 600px) {
.header-bg{
 background-size: cover;
}
}