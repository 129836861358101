#register{
   display: grid;
   grid-template-columns: 60% 40%;
  
}

  .reg-one{
    padding: 1rem;
   align-self: center;
    /* text-align: center; */

  }
  .reg-text{
    max-width: 35rem;
    padding: 1rem;
  }
  .reg-text h3{
    font-size: 2rem;
    font-weight: 500;
    line-height: 1.4;
    text-transform: uppercase;
    color: var(--dark-text);
    margin: 1.5rem 0;
  }
  .reg-text p{
    width: 50%;
    min-width: 35rem;
    margin: 0 auto;
    font-weight: 500;
    color: var(--dark-text);
  }
  .reg-two{
    height: 100vh;
  }
  .reg-two img{
    width: 100%;
    height: 100%;
  }
/* #register video{
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
   height: 100%;
   object-fit: cover;
    outline: none;
} */ 

@media screen and (max-width: 600px) {
    #register{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  /* width: 350px;  */
}
.reg-two{
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    filter: blur(4px);
}
.reg-one{
  padding: 0;

}
    }