.build{
    text-align: center;
}
.build-img{
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
  
}
.build-img img{
    width: 500px;
    height: 100%;
}
.build h1 {
    font-size: 4rem;
    line-height: 1.4;
    text-transform: uppercase;
    color: var(--dark-blue);
    position: relative;
    padding-bottom: 2rem;
  }
  .build p {
    margin: 3rem 0;
    color: var(--dark-blue);
    font-size: 3rem;
    width: 70%;
    animation: bounce 0.8s ease infinite alternate;;
  }
 
  
  @keyframes bounce {
    0% {
      transform: translateY(-25%);
    }
    100% {
      transform: translateY(25%);
    }
  }

  @media screen and (max-width: 769px) {
    /* hide nav button on navbar */
    .build h1 {
      font-size: 2rem;
    }
    .build-img img{
      width: 300px;
  }
  }